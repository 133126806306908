<template>
  <div class="order-id">
    <span class="icon" />
    <span class="custom-title">{{ orderId }}</span>
    <span class="cont">{{ period }}</span>
  </div>
</template>

<script>
export default {
  name: 'OrderId',
  props: {
    orderId: {
      type: [String, Number],
      default: ''
    },
    period: { // 期次
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {}
  }
}
</script>
<style lang="scss" scoped>
.order-id{
  height: 100px;
  line-height: 100px;
  background: #FFFFFF;
  position: relative;
  border-bottom: 4px dashed #F1F1F1FF;
  .icon{
    display: table-cell;
    width: 44px;
    height: 44px;
    background: url(../../../../assets/images/userCenter/order-id-icon.png) no-repeat center center;
    background-size: 100%;
  }
  .custom-title{
    padding-left:  20px;
    display: table-cell;
    font-size: 28px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    color: #002424;
  }
  .cont{
    position: absolute;
    font-size: 28px;
    font-family: Roboto-Bold, Roboto;
    font-weight: bold;
    color: #999999;
    right: 0;
    top: 0;
  }
  .blue-color{
    color: #0BB8E4FF;
  }
}
</style>
