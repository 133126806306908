<template>
  <div>
    <base-template :is-empty="isEmpty" :header-title="$t('navbar.repaymentRecord')">
      <div class="main-box">
        <ul class="list-box">
          <li v-for="(item,index) in dataList" :key="index" class="list-item">
            <order-id ref="orderId" :period="item.sort" :order-id="$t('navbar.termPeriod')" />
            <list-item ref="listItem" :cont-text="'$'+formatCurrency(item.repayment_amount)" :title="$t('navbar.repaymentThis')" />
            <list-item ref="listItem" :cont-text="item.pay_time" :title="$t('navbar.repaymentDate')" />
            <list-item ref="listItem" :cont-text="item.payment_name" :title="$t('navbar.paymentMethod')" />
            <list-item ref="listItem" :cont-text="item.payment_code" :title="$t('navbar.PaymentCode')" class="no-border" :is-icon="true" />
          </li>
        </ul>
      </div>
    </base-template>
  </div>
</template>

<script>
import OrderId from '@/views/userCenter/paymentHistory/components/orderId.vue'
import ListItem from '@/views/userCenter/paymentHistory/components/listItem'

import { getBillLogList } from '@/api/axios'
export default {
  components: { OrderId, ListItem },
  data() {
    return {
      dataList: [],
      isEmpty: false
    }
  },
  created() {
    this.getBillLogList()
  },
  methods: {
    async getBillLogList() {
      const params = this.$route.query
      const { data } = await getBillLogList(params) || {}
      this.dataList = data
      this.isEmpty = !this.dataList.length
    }
  }
}
</script>
<style lang="scss" scoped>
.main-box{
  padding-top: 32px;
  .list-box{
    .list-item{
      width: 100%;
      min-height: 433px;
      background: #FFFFFF;
      box-shadow: 0px 14px 35px 0px rgba(170, 170, 170, 0.06);
      border-radius: 24px;
      margin-bottom: 24px;
      overflow: hidden;
      padding: 0 32px;
      :deep(.no-border){
        border: none;
      }
    }
  }
}

</style>
