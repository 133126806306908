<template>
  <div :class="{'is-icon':isIcon}" class="item">
    <span class="custom-title">{{ title }}</span>
    <span class="cont">{{ contText }}</span>
    <span class="icon" @click="handleClick(contText)" />
  </div>
</template>

<script>
export default {
  name: 'ListItem',
  props: {
    isIcon: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    contText: {
      type: String,
      default: ''
    }
  },

  data() {
    return {}
  },
  methods: {
    handleClick(contText) {
      this.$emit('iconClick')
      this.$copyText(contText).then(() => {
        this.$toast({
          type: 'success',
          message: this.$t('navbar.copySuccess'),
          className: 'copy-toast',
          duration: 1800
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.item{
  height: 80px;
  line-height: 80px;
  background: #FFFFFF;
  position: relative;
  border-bottom: 1px solid #F0F0F0;
  .icon{
    display: none;
    width: 32px;
    height: 100%;
    // background: url(../../../../assets/images/userCenter/copy-icon.png) no-repeat center center;
    background-size: 100%;
    position: absolute;
    top: 0;
    right: 5px;
  }
  .custom-title{
    font-size: 26px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    color: #666666;
  }
  .cont{
    position: absolute;
    font-size: 26px;
    font-family: Roboto-Medium, Roboto;
    font-weight: 500;
    color: #333333;
    right: 0;
    top: 0;
  }
}
.is-icon{
  .icon{
    display: table-cell;
  }
  .cont{
    right: 53px;
  }
}
</style>
<style>
.copy-toast{
  min-width: 200px;
}
</style>
